import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./style.css";
import Keycloak from "keycloak-js";
import { constants } from "./consts/Variables";
import Footer from "./components/footer/Footer";

const root = ReactDOM.createRoot(document.getElementById("root"));

const keycloak = new Keycloak({
  url:
    process.env.NODE_ENV === "development"
      ? "https://xr2learn-keycloak.lab.synelixis.com"
      : constants.AUTH_URL,
  realm:
    process.env.NODE_ENV === "development" ? "XR2Learn" : constants.AUTH_REALM,
  clientId:
    process.env.NODE_ENV === "development"
      ? "marketplace"
      : constants.AUTH_CLIENT,
  enableCors: true,
});

const onUpdateToken = () => {
  keycloak
    .updateToken(30)
    .then(() => {
      localStorage.setItem("kc_token", keycloak.token);
    })
    .error((error) => {
      console.log(error);
    });
};

const onLoadUser = () => {
  return keycloak
    .loadUserProfile()
    .then((profile) => {
      localStorage.setItem("user", JSON.stringify(profile));
    })
    .catch((error) => {
      console.log(error);
    });
};

const onLogout = () => {
  localStorage.removeItem("kc_token");
  keycloak.logout({ redirectUri: window.location.origin + "/" });
};

const onLogin = () => {
  keycloak.login({ redirectUri: window.location.origin + "/" });
};

keycloak.onAuthLogout = function () {
  localStorage.removeItem("kc_token");
};

keycloak.onAuthSuccess = function () {
  localStorage.setItem("kc_token", keycloak.token);
  onLoadUser();
};

keycloak
  .init({
    onLoad: "check-sso",
    checkLoginIframe: false,
  })
  .then((authenticated) => {
    if (authenticated) {
      localStorage.setItem("kc_token", keycloak.token);
      onLoadUser();
    } else {
      onLogin();
    }
  });

root.render(
  <React.StrictMode>
    <App onLogout={onLogout} token={localStorage.getItem("kc_token")} />
  </React.StrictMode>
);
