import { makeAutoObservable } from "mobx";
import { GET, PATCH } from "../apis/apis";

class UserStore {
  constructor({ errStore, textStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
    this.textStore = textStore;
  }

  token = localStorage.getItem("kc_token")
  user = null;
  isUserModalOpen = false;
  userDetails = null;

  setUserDetails = (value) => {
    this.userDetails = value;
  };

  setIsUserModalOpen = (value) => {
    this.isUserModalOpen = value;
  };

  setUser = (value) => {
    this.user = value;
  };

  setToken = (value) => {
    this.token = value;
  };

  getToken = () => {
    this.token = localStorage.getItem("kc_token")
  };

  formUserDetails = [];

  // user
  GetUser = () => {
    this.errStore.setLoading(true);
    GET(`/user`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setUser(data);
        this.formUserDetails = [
          {
            name: "id",
            value: this.user.id,
          },
          {
            name: "bio",
            value: this.user.bio,
          },
          {
            name: "first_name",
            value: this.user.first_name,
          },
          {
            name: "last_name",
            value: this.user.last_name,
          },
          {
            name: "email",
            value: this.user.email,
          },
          {
            name: "external_link",
            value: this.user.external_link,
          },
        ];
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  UpdateUserProfile = (values) => {
    this.errStore.setLoading(true);
    PATCH(`/user`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setUser(data);
        this.setIsUserModalOpen(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetUserByUsername = (username) => {
    this.errStore.setLoading(true);
    GET(`/user/username/${username}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.setUserDetails(data)
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };
}

export default UserStore;
