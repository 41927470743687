import axios from "axios";
import { constants } from "../consts/Variables";

export const API_URL =
  process.env.NODE_ENV === "development"
    ? `${constants.API}`
    : `${constants.API}`;

export const CONTENT_MANAGEMENT_API_URL =
  process.env.NODE_ENV === "development"
    ? `${constants.CONTENT_MANAGEMENT_API}`
    : `${constants.CONTENT_MANAGEMENT_API}`;

const api = axios.create({ baseURL: API_URL });
const content_management_api = axios.create({
  baseURL: CONTENT_MANAGEMENT_API_URL,
});

export const platform_api = axios.create({ baseURL: constants.PLATFORM });

let token = null;
let headers = null;

export const init = () => {
  token = localStorage.getItem("kc_token");
  headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const POST = async (url, values) => {
  init();
  const { data } = await api.post(url, values, headers);
  return data;
};

export const SEND_MAIL = async (url, values) => {
  init();
  const { data } = await api.post(url, values);
  return data;
};

export const GET = async (url) => {
  init();
  const { data } = await api.get(url, headers);
  return data;
};

export const PUT = async (url, values) => {
  init();
  const { data } = await api.put(url, values, headers);
  return data;
};

export const PATCH = async (url, values) => {
  init();
  const { data } = await api.patch(url, values, headers);
  return data;
};

export const DELETE = async (url, values) => {
  init();
  const { data } = await api.delete(url, headers);
  return data;
};

export const GET_CONTENT_MANAGEMENT = async (url) => {
  init();
  const { data } = await content_management_api.get(url, headers);
  return data;
};

export const GET_PLATFORM = async (url) => {
  init();
  const { data } = await platform_api.get(url, headers);
  return data;
};

export const POST_PLATFORM = async (url, values) => {
  init();
  const { data } = await platform_api.post(url, values, headers);
  return data;
};

export const PUT_PLATFORM = async (url, values) => {
  init();
  const { data } = await platform_api.put(url, values, headers);
  return data;
};

export const DELETE_PLATFORM = async (url) => {
  init();
  const { data } = await platform_api.delete(url, headers);
  return data;
};

export const isServer = typeof window === "undefined";
