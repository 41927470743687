import React from "react";
import { useMobxStores } from "../stores/stores";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Row } from "antd";
import { constants } from "../consts/Variables";
import { ShoppingCartOutlined, UsergroupAddOutlined } from "@ant-design/icons";

const Home = () => {
  const { userStore } = useMobxStores();

  const navigate = useNavigate();

  return (
    <div
      style={{
        paddingLeft: "20%",
        paddingTop: "5%",
        height: "100vh",
        color: "white",
      }}
      className="header-color"
    >
      <Row align={"middle"} justify={"left"} style={{ fontSize: "140px" }}>
        <b>Hello {userStore.user && userStore.user.first_name}!</b>
      </Row>{" "}
      <Row
        align={"middle"}
        justify={"left"}
        style={{ fontSize: "24px", marginBottom: "-30px" }}
      >
        <p>
          Search the{" "}
          <a
            style={{ color: "rgba(208, 31, 223)" }}
            onClick={() => navigate("/catalogue")}
          >
            {" "}
            Catalogue
          </a>{" "}
          to find all the new tools and content!
        </p>
      </Row>
      <Row
        align={"middle"}
        justify={"left"}
        style={{ fontSize: "24px", marginBottom: "-30px" }}
      >
        <p>
          <UsergroupAddOutlined
            style={{ fontSize: "54px", color: "rgba(208, 31, 223)" }}
          />{" "}
          Join our{" "}
          <a style={{ color: "rgba(208, 31, 223)" }} href={constants.FORUM_URL}>
            {" "}
            community
          </a>{" "}
          forum to share knowledge and discuss with other members.
        </p>
      </Row>
      <Row align={"middle"} justify={"left"} style={{ fontSize: "24px" }}>
        <p>
          <ShoppingCartOutlined
            style={{ fontSize: "54px", color: "rgba(208, 31, 223)" }}
          />{" "}
          Head to the
          <a
            style={{ color: "rgba(208, 31, 223)" }}
            onClick={() => navigate("/marketplace")}
          >
            {" "}
            Marketplace
          </a>{" "}
          to find all the new exciting items or publish your own!
        </p>
      </Row>
    </div>
  );
};
export default observer(Home);
