import React from "react";
import { Avatar, Card, Skeleton } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";

const UserPreview = () => {
  const { userStore, errorStore } = useMobxStores();
  const { Meta } = Card;

  return (
    <div>
      <Card style={{ maxWidth: "400px" }}>
        <Skeleton loading={errorStore.loading} avatar active>
          {userStore.userDetails && (
            <Meta
              avatar={<Avatar src={userStore.userDetails.photo} />}
              title={`${userStore.userDetails.first_name} ${userStore.userDetails.last_name}`}
              description={userStore.userDetails.bio}
            />
          )}
        </Skeleton>
      </Card>
    </div>
  );
};

export default observer(UserPreview);
