import React, { useEffect, useState } from "react";
import {
  MoreOutlined,
  DownloadOutlined,
  HeartFilled,
  EyeOutlined,
  DeleteFilled,
  ShoppingCartOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Image,
  Skeleton,
  Popconfirm,
  Tooltip,
  Space,
  Input,
  Tag,
  Row,
  Col,
  Popover,
  Typography,
} from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { useNavigate } from "react-router-dom";
import UserPreview from "../profile/UserPreview";
import { useParams } from "react-router-dom";

const { Meta } = Card;

const Item = (props) => {
  const { styleStore, marketplaceStore, userStore } = useMobxStores();
  const [display, setDisplay] = useState(false);
  const [hover, SetOnHover] = useState(false);
  let params = useParams();
  const { Paragraph, Link } = Typography;
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading] = useState(true);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLink, setIsEditingLink] = useState(false);
  const [description, setDescription] = useState(props.item.description);
  const [externalLink, setExternalLink] = useState(props.item.external_link);
  const createdDate = new Date(props.item.created);
  const formattedDate = createdDate.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleEditLink = () => {
    setIsEditingLink(true);
  };
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  const handleDescriptionChange = (e) => {
    props.item.description = e.target.value;
  };
  const handleDescriptionChangeLink = (e) => {
    props.item.external_link = e.target.value;
  };
  const truncateText = (text, limit) => {
    if (!text) return "";
    if (text.length <= limit) {
      return text;
    }
    const truncated = text.substr(0, limit);
    return truncated.substr(0, truncated.lastIndexOf(" ")) + "...";
  };
  const truncatedDescription = truncateText(description, 300);
  const handleSubmit = async () => {
    // Toggle off edit mode
    setIsEditing(false);
    setIsEditingLink(false);

    // Assuming you have an API endpoint where you can update the item description

    const postData = new FormData();
    props.item.description &&
      postData.append("description", props.item.description);
    // console.log(postData);
    props.item.external_link &&
      postData.append("external_link", props.item.external_link);
    // console.log(marketplaceStore);
    // console.log(params.pk);
    marketplaceStore.UpdateMarketPlaceItem(params.pk, postData);
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const styles = {
    tableRowCell: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "250px",
      lineBreak: "auto",
      wordBreak: "breal-all",
    },
  };

  const tableRowCellWrapper = (text, shouldCallPopover = true) => {
    return (
      <div
        style={{
          ...styles.tableRowCell,
        }}
      >
        {shouldCallPopover ? (
          <Typography.Text
            style={{
              wordBreak: "break-all",
              cursor: "pointer",
              whiteSpace: expanded ? "normal" : "nowrap",
            }}
            onClick={handleToggle}
          >
            {text}{" "}
            {/* <Space style={{ display: "inline" }}>
              {expanded ? <UpOutlined /> : <DownOutlined />}
            </Space> */}
          </Typography.Text>
        ) : (
          <>{text}</>
        )}
      </div>
    );
  };

  const onClick = (values) => {
    marketplaceStore.GetMarketPlaceItemsByTypeOrName(values);
    navigate(`/?tag=${values.value}`);
  };

  const onUsernameClick = (username) => {
    navigate(`../portfolio/${username}`);
  };

  const OnUsernameMouseOver = (username) => {
    userStore.GetUserByUsername(username);
  };

  // const pauseVideo = () => {
  //   const videoElement = document.getElementById("video-player");
  //   if (videoElement) {
  //     videoElement.pause();
  //   }
  // };

  // const onPlayVideo = () => {
  //   marketplaceStore.setPlayingId(null);
  //   marketplaceStore.setPlay(false);
  //   marketplaceStore.setAudio(null);
  // };

  // const onPlayClick = () => {
  //   marketplaceStore.setAudio(props.item.source_media);
  //   marketplaceStore.setPlay(true);
  //   marketplaceStore.setPlayingId(props.item.id);
  //   pauseVideo();
  // };

  // const onPauseClick = () => {
  //   marketplaceStore.setAudio(null);
  //   marketplaceStore.setPlay(false);
  //   marketplaceStore.setPlayingId(null);
  // };

  // const onRePlayClick = () => {
  //   marketplaceStore.setAudio(null);
  //   marketplaceStore.setPlay(false);
  //   marketplaceStore.setPlayingId(null);

  //   setTimeout(() => {
  //     marketplaceStore.setAudio(props.item.source_media);
  //     marketplaceStore.setPlay(true);
  //     marketplaceStore.setPlayingId(props.item.id);
  //   }, 100);
  // };

  const onDownload = (src, name) => {
    async function downloadMedia(src, name) {
      const mediaUrl = src;

      try {
        const response = await fetch(mediaUrl);
        const blob = await response.blob();
        const contentType = response.headers.get("content-type");
        let fileExtension = "";
        let fileName = name;

        if (contentType.startsWith("image/")) {
          fileExtension = "jpg";
        } else if (contentType.startsWith("audio/")) {
          fileExtension = "mp3";
        } else if (contentType.startsWith("video/")) {
          fileExtension = "mp4";
        } else if (contentType.startsWith("software/")) {
          fileExtension = "zip";
        }

        fileName += `.${fileExtension}`;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading media:", error);
      }
    }

    downloadMedia(src, name);
  };

  const onDelete = (id) => {
    marketplaceStore.DeleteMarketPlaceItemById(id);
  };

  const onBuyNFT = (id) => {
    var values = {
      account: localStorage.getItem("metaMask"),
    };
    marketplaceStore.BuyNFT(id, values);
  };

  // const onAddtoCart = (id) => {
  //   let values = marketplaceStore.cart;
  //   values.items.push(id);
  //   marketplaceStore.UpdateCart(values);
  // };

  const onRemoveFromCart = (id) => {
    let values = marketplaceStore.cart;
    for (var i = 0; i < values.items.length; i++) {
      if (values.items[i] === id) {
        values.items.splice(i, 1);
        break;
      }
    }
    marketplaceStore.UpdateCart(values);
  };

  const onAddtoWishlist = (id) => {
    let values = marketplaceStore.wishlist;
    values.items.push(id);
    marketplaceStore.UpdateWishlist(values);
  };

  const onRemoveFromWishlist = (id) => {
    let values = marketplaceStore.wishlist;
    for (var i = 0; i < values.items.length; i++) {
      if (values.items[i] === id) {
        values.items.splice(i, 1);
        break;
      }
    }
    marketplaceStore.UpdateWishlist(values);
  };

  const clickableClasses = [
    "ant-card-head-wrapper",
    "ant-card-meta-title",
    "ant-card-meta-description",
    "ant-card-body",
  ];
  const handleButtonClick = () => {
    // Perform the action you want when the button is clicked
    // For example, redirect to a certain page
    console.log(marketplaceStore.license.source);
    window.location.href = marketplaceStore.license.find(
      (item) => item.id === marketplaceStore.NFT.license
    ).source;
  };

  useEffect(() => {
    marketplaceStore.GetLicense();
    setTimeout(() => {
      setDisplay(true);
    }, "1000");
  }, []);

  return display ? (
    <>
      <Card
        onMouseOver={() => SetOnHover(true)}
        onMouseLeave={() => SetOnHover(false)}
        title={
          <Space size={"large"} direction="horizontal">
            {props.item.nft !== null ? (
              <Avatar
                src={
                  marketplaceStore.NFT.chainid === 11155111
                    ? marketplaceStore.ethereum_icon
                    : marketplaceStore.polygon_icon
                }
              />
            ) : (
              ""
            )}
            <p
              onClick={() => onUsernameClick(props.item.owner_name)}
              onMouseOver={() => OnUsernameMouseOver(props.item.owner_name)}
              className="not"
              style={{
                maxWidth:
                  props.item.nft && !props.single
                    ? "150px"
                    : props.single
                    ? "100%"
                    : "180px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Popover
                style={{ backgroundColor: "transparent" }}
                placement={"topLeft"}
                content={<UserPreview />}
              >
                {/* {props.item.owner_name} */}
              </Popover>
            </p>
            {/* {props.item.id === marketplaceStore.playingId &&
              marketplaceStore.play && (
                <div>
                  <div class="playing-icon">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                  </div>
                </div>
              )} */}
          </Space>
        }
        key={props.item.id}
        onClick={(e) => {
          if (clickableClasses.includes(e.target.className)) {
            navigate(`/marketplace/${props.item.id}`);
          }
        }}
        bordered={true}
        hoverable={true}
        bodyStyle={{ zIndex: 998 }}
        style={{
          width: props.single ? "auto" : 300,
          height: "auto",
          marginLeft: "5px",
          backgroundColor: hover ? "whitesmoke" : "white",
          zIndex: 999,
          marginBottom: 50,
        }}
        cover={
          // props.item.type === "video" ? (
          //   <video
          //     id={"video-player"}
          //     width="auto"
          //     height={"200px"}
          //     controls
          //     onPlay={() => onPlayVideo()}
          //   >
          //     <source src={props.item.source_media} />
          //     Your browser does not support HTML5 video.
          //   </video>
          // ) : (
          <div className="card-cover">
            <Image
              style={{
                height: props.single ? "auto" : "200px",
                objectFit: "cover",
                opacity: 1,
              }}
              alt={props.item.type}
              src={props.item.display_image}
              preview={true}
              width={"100%"}
            />
            {/* {props.item.type === "audio" && (
                <div className="play-icon-container">
                  {!marketplaceStore.play && (
                    <PlayCircleOutlined
                      onClick={() => onPlayClick()}
                      onPlayClick
                      className="play-icon"
                    />
                  )}

                  {marketplaceStore.play &&
                    marketplaceStore.playingId === props.item.id && (
                      <PauseCircleFilled
                        type="pause"
                        onClick={() => onPauseClick()}
                        onPlayClick
                        className="play-icon"
                      />
                    )}
                  {marketplaceStore.play &&
                    marketplaceStore.playingId !== props.item.id && (
                      <PlayCircleOutlined
                        onClick={() => onRePlayClick()}
                        onPlayClick
                        className="play-icon"
                      />
                    )}
                </div>
              )} */}
          </div>
          // )
        }
        actions={
          !props.single
            ? [
                marketplaceStore.cart.items.includes(props.item.id) ? (
                  <Tooltip title="Remove this item from your cart!">
                    <DeleteFilled
                      style={{ color: "gray", fontSize: "24px" }}
                      onClick={() => onRemoveFromCart(props.item.id)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      props.item.nft === null
                        ? props.item.source_media &&
                          props.item.source_media.includes("ipfs")
                          ? "Download this item!"
                          : props.item.type === "software" &&
                            "Redirect to software repository"
                        : !marketplaceStore.metaMaskIsConnected
                        ? "Connect to metaMask first!"
                        : "Buy this item!"
                    }
                  >
                    {props.item.nft !== null ? (
                      <Button
                        type="text"
                        disabled={
                          !marketplaceStore.metaMaskIsConnected ? true : false
                        }
                        icon={
                          <ShoppingCartOutlined
                            style={{ color: "black", fontSize: "24px" }}
                            onClick={() => onBuyNFT(props.item.nft.id)}
                          />
                        }
                      ></Button>
                    ) : props.item.nft === null &&
                      props.item.type === "software" &&
                      props.item.source_media &&
                      !props.item.source_media.includes("ipfs") ? (
                      <Button
                        type="text"
                        onClick={() => {
                          window.open(props.item.source_media);
                        }}
                      >
                        Software
                      </Button>
                    ) : (
                      <DownloadOutlined
                        style={{ color: "black", fontSize: "24px" }}
                        onClick={() =>
                          onDownload(props.item.source_media, props.item.name)
                        }
                      >
                        Download
                      </DownloadOutlined>
                    )}
                  </Tooltip>
                ),
                <Tooltip title="More details about this item!">
                  <Button
                    type="text"
                    onClick={() => navigate(`/marketplace/${props.item.id}`)}
                  >
                    View
                  </Button>
                </Tooltip>,
                <Tooltip title={`${props.item.views} views`}>
                  <EyeOutlined style={{ color: "black", fontSize: "24px" }} />
                </Tooltip>,
              ]
            : [
                marketplaceStore.cart.items.includes(props.item.id) ? (
                  <Tooltip title="Remove this item from your cart!">
                    <DeleteFilled
                      style={{ color: "gray", fontSize: "24px" }}
                      onClick={() => onRemoveFromCart(props.item.id)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      props.item.nft === null
                        ? "Download this item!"
                        : !marketplaceStore.metaMaskIsConnected
                        ? "Connect to metaMask first!"
                        : "Buy this item!"
                    }
                  >
                    {props.item.nft !== null ? (
                      <Button
                        type="text"
                        disabled={
                          marketplaceStore.metaMaskIsConnected === null
                            ? true
                            : false
                        }
                        icon={
                          <ShoppingCartOutlined
                            style={{ color: "black", fontSize: "24px" }}
                            onClick={() => onBuyNFT(props.item.nft)}
                          />
                        }
                      ></Button>
                    ) : props.item.type === "software" &&
                      props.item.source_media &&
                      !props.item.source_media.includes("ipfs") ? (
                      <Button
                        type="text"
                        onClick={() => {
                          window.open(props.item.source_media);
                        }}
                      >
                        View the Code
                      </Button>
                    ) : (
                      <Button
                        type="text"
                        onClick={() => {
                          onDownload(props.item.source_media);
                        }}
                      >
                        Download
                      </Button>
                    )}
                  </Tooltip>
                ),
              ]
        }
        extra={[
          <Tooltip
            title={
              marketplaceStore.wishlist.items.includes(props.item.id)
                ? "Remove this item from your wishlist!"
                : "Add this item to your wishlist!"
            }
          >
            <HeartFilled
              onClick={() =>
                marketplaceStore.wishlist.items.includes(props.item.id)
                  ? onRemoveFromWishlist(props.item.id)
                  : onAddtoWishlist(props.item.id)
              }
              style={{
                fontSize: "24px",
                color: marketplaceStore.wishlist.items.includes(props.item.id)
                  ? styleStore.primaryColor
                  : "gray",
                marginLeft: "4px",
              }}
            />
          </Tooltip>,
          userStore.user && props.item.owner === userStore.user.id && (
            <Popconfirm
              onConfirm={() => onDelete(props.item.id)}
              title={"Delete this item?"}
            >
              <MoreOutlined
                style={{ fontSize: "24px", color: styleStore.primaryColor }}
              />
            </Popconfirm>
          ),
        ]}
      >
        {props.item.type === "text" ||
          (props.item.type === "software" && props.single && (
            <div>
              <a href={marketplaceStore.source}>file</a>
            </div>
          ))}

        <Space direction="vertical" style={{ zIndex: 0, width: "100%" }}>
          <Meta style={{ zIndex: 0 }} title={props.item.name} />
          <a href={`../portfolio/${props.item.owner_name}`}>
            <Meta description={`@${props.item.owner_name}`} />
          </a>

          {props.single && (
            <>
              {/* <Meta description={props.item.tags} /> */}
              {/* <Meta description={tableRowCellWrapper(props.item.description)} /> */}
              <div>
                {/* Your existing Meta component */}
                {userStore.user &&
                props.item.owner === userStore.user.id &&
                props.item.description ? (
                  <>
                    {isEditing ? (
                      <div>
                        <input
                          type="text"
                          value={props.item.description}
                          onChange={handleDescriptionChange}
                        />
                        <button onClick={handleSubmit}>
                          <SaveOutlined />
                        </button>
                      </div>
                    ) : (
                      <Row>
                        {" "}
                        <Meta
                          description={
                            <>
                              <Paragraph>
                                {isExpanded
                                  ? description
                                  : truncatedDescription}
                              </Paragraph>
                              <Link
                                onClick={toggleExpanded}
                                style={{ display: "block", marginTop: 8 }}
                              >
                                {isExpanded ? "Read less" : "Read more"}
                              </Link>
                            </>
                          }
                        />
                        {/* props.item.description */}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                          onClick={handleEdit}
                          style={{
                            height: "30px",
                            width: "auto", // Adjust as needed
                          }}
                        >
                          <EditOutlined />
                        </button>
                      </Row>
                    )}
                  </>
                ) : (
                  <Meta description={props.item.description} />
                )}
              </div>
              {userStore.user &&
              props.item.owner === userStore.user.id &&
              props.item.external_link ? (
                <>
                  {" "}
                  {isEditingLink ? (
                    <div>
                      <input
                        type="text"
                        value={props.item.external_link}
                        onChange={handleDescriptionChangeLink}
                      />
                      <button onClick={handleSubmit}>
                        <SaveOutlined />
                      </button>
                    </div>
                  ) : (
                    <Row>
                      {" "}
                      <a
                        href={props.item.external_link}
                        style={{ textDecoration: "underline" }}
                      >
                        <Meta description={props.item.external_link} />
                      </a>
                      &nbsp;&nbsp;
                      <button onClick={handleEditLink}>
                        <EditOutlined />
                      </button>
                    </Row>
                  )}
                </>
              ) : (
                <a
                  href={props.item.external_link}
                  style={{ textDecoration: "underline" }}
                >
                  <Meta description={props.item.external_link} />
                </a>
              )}
              {props.item.type === "MOOC" && props.item.source_media && (
                <a
                  href={props.item.source_media}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Meta
                    description={props.item.source_media}
                    style={{
                      marginBottom: "10px",
                    }}
                  />
                </a>
              )}

              <Meta description={formattedDate} />

              {marketplaceStore.unlockable_source ? (
                <>
                  <Card>
                    <Row>
                      <Col>
                        <Typography.Text style={{ fontWeight: "bold" }}>
                          Find your content
                        </Typography.Text>

                        <a href={marketplaceStore.unlockable_source}> here</a>
                      </Col>
                    </Row>
                    <Row>
                      <Typography.Text style={{ fontWeight: "bold" }}>
                        Unlock Content:&nbsp;
                      </Typography.Text>

                      {marketplaceStore.unlockable_content_text}
                    </Row>
                  </Card>
                </>
              ) : (
                props.item.has_unlockable_content &&
                (marketplaceStore.metaMaskIsConnected ? (
                  <Button
                    type="primary"
                    onClick={() =>
                      marketplaceStore.UnlockContent(props.item.nft)
                    }
                  >
                    Unlock Content
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() =>
                      alert(
                        "Please connect to your MetaMask wallet and try again"
                      )
                    }
                  >
                    Unlock Content
                  </Button>
                ))
              )}

              {props.item.nft !== null && (
                <Space direction="vertical">
                  <Row gutter={16}>
                    <Col span={8}>
                      Price{" "}
                      {marketplaceStore.NFT.chainid === 11155111
                        ? "(ETH)"
                        : "(MATIC)"}
                    </Col>
                    <Col span={12}>
                      <Input
                        style={{ backgroundColor: "white", color: "black" }}
                        disabled
                        value={marketplaceStore.NFT.price}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>License</Col>
                    <Col span={12}>
                      <Button
                        onClick={handleButtonClick}
                        type="text"
                        style={{ padding: 0, border: 0 }}
                      >
                        {/* {console.log(
                          marketplaceStore.license.find(
                            (item) => item.id === marketplaceStore.NFT.license
                          ).source
                        )} */}

                        <Input
                          style={{
                            backgroundColor: "#000080",
                            color: "white",
                          }}
                          type="button"
                          value={
                            marketplaceStore.license.find(
                              (item) => item.id === marketplaceStore.NFT.license
                            ).name
                          }
                        />
                      </Button>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>Supply</Col>
                    <Col span={12}>
                      <Input
                        style={{ backgroundColor: "white", color: "black" }}
                        disabled
                        value={marketplaceStore.NFT.supply}
                      />
                    </Col>
                  </Row>
                </Space>
              )}
            </>
          )}
          {props.single && props.item.tags && (
            <Row>
              {props.item.tags.map((item, index) => {
                return (
                  <Tag
                    key={index}
                    onClick={() => onClick({ order: "tag", value: item })}
                    style={{
                      width: "70px",
                      textAlign: "center",
                      cursor: "pointer",
                      borderRadius: "15px",
                    }}
                    color="white"
                    className="tags-color"
                  >
                    {item}
                  </Tag>
                );
              })}
            </Row>
          )}
        </Space>
      </Card>
      {/* {play && (
              <audio autoPlay={play}  id="myAudio" controls style={{ width: "100%" }}>
                <source src={props.item.source_media} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            )} */}
    </>
  ) : (
    <Card
      key={props.item.id}
      style={{
        width: props.single ? "auto" : 300,
        marginTop: 16,
      }}
      actions={[<Skeleton Button />]}
      extra={
        <div style={{ marginRight: "8px" }}>
          <HeartFilled style={{ fontSize: "24px", color: "whitesmoke" }} />
        </div>
      }
    >
      <Skeleton loading={loading} avatar active>
        <Meta
          avatar={
            <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=2" />
          }
          title="Card title"
          description="This is the description"
        />
      </Skeleton>
    </Card>
  );
};

export default observer(Item);
