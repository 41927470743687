import React from "react";
import { Layout, Typography, Button, Space } from "antd";
import { Link } from "react-router-dom";

const { Footer: AntFooter } = Layout;
const { Text, Link: AntLink } = Typography;

const Footer = () => {
  return (
    <AntFooter
      style={{
        textAlign: "center",

        backgroundColor: "#000080",
        color: "#fff",
        height: 25,
        padding: "4px 0",
        marginTop: 100,
        position: "fixed",
        zIndex: 1000,
        width: "100%",
        bottom: 0,
      }}
    >
      <Text>
        {/* For further information please refer to our{" "} */}
        <AntLink>
          <Link
            to="/privacy-policy"
            style={{ color: "#fff", textDecoration: "underline" }}
          >
            Privacy Policy
          </Link>
        </AntLink>
      </Text>
    </AntFooter>
  );
};

export default Footer;
