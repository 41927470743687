import React, { useEffect, useState } from "react";
import { useMobxStores } from "../stores/stores";
import { observer } from "mobx-react";
import { Row, Col, Divider, Tabs, Card, Empty, Image, Button } from "antd";
import Item from "../components/marketplace/Item";
import UserModalForm from "../components/profile/UserModalForm";
import {
  LinkedinOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";

const { Meta } = Card;

const Profile = () => {
  const { userStore, marketplaceStore } = useMobxStores();
  const [user, setUser] = useState(null);
  let params = useParams();

  useEffect(() => {
    marketplaceStore.GetMarketPlaceItems();
    marketplaceStore.GetCreatedMarketPlaceItems(params.username);
    marketplaceStore.GetOwnedMarketPlaceItems(params.username);
    userStore.GetUser();
    userStore.GetUserByUsername(params.username);
  }, [marketplaceStore, params]);

  useEffect(() => {
    setUser(
      params.username === userStore.user && userStore.user.username
        ? userStore.user
        : userStore.userDetails
    );
  }, [userStore.user, userStore.userDetails, params]);

  const onChange = (key) => {
    console.log(key);
  };

  const isInstagramLink = (url) => {
    const instagramRegex =
      /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_]+\/?$/;
    return instagramRegex.test(url);
  };

  const isTwitterLink = (url) => {
    const twitterRegex =
      /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/;
    return twitterRegex.test(url);
  };

  const isLinkedInLink = (url) => {
    const linkedInRegex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
    return linkedInRegex.test(url);
  };

  const items = [
    {
      key: "1",
      label: `Owned`,
      children: (
        <Row justify={"center"} align={"middle"} gutter={[30, 20]}>
          {marketplaceStore.OwnedMarketPlaceItems.map((item, index) => {
            return (
              <Col>
                <Item key={index} item={item} />
              </Col>
            );
          })}
          {marketplaceStore.OwnedMarketPlaceItems.length === 0 && (
            <Empty description="No items found" />
          )}
        </Row>
      ),
    },
    {
      key: "2",
      label: `Created`,
      children: (
        <Row justify={"center"} align={"middle"} gutter={[30, 20]}>
          {marketplaceStore.CreatedMarketPlaceItems.map((item, index) => {
            return (
              <Col>
                <Item key={index} item={item} />
              </Col>
            );
          })}
          {marketplaceStore.CreatedMarketPlaceItems.length === 0 && (
            <Empty description="No items found" />
          )}
        </Row>
      ),
    },
  ];
  return (
    <div>
      {user != null && (
        <Row align={"top"} justify={"space-around"} gutter={[10, 10]}>
          <Divider />
          <Col span={1} xl={1} xxl={1} md={1} sm={0} xs={0}></Col>
          <Col
            span={7}
            xl={7}
            xxl={7}
            md={7}
            sm={24}
            xs={24}
            style={{ textAlign: "center", alignItems: "center" }}
          >
            <Card
              hoverable
              style={{
                width: "80%",
              }}
              cover={
                <Image
                  alt="user-photo-profil"
                  src={user && user.photo}
                  preview={false}
                />
              }
              actions={[
                params.username === userStore.user.username && (
                  <Button
                    type="primary"
                    onClick={() => userStore.setIsUserModalOpen(true)}
                  >
                    Edit
                  </Button>
                ),
              ]}
            >
              <Meta title={user && `${user.first_name} ${user.last_name}`} />
              <Meta
                style={{ whiteSpace: "pre-line", marginTop: "8px" }}
                description={user && user.bio}
              />
              {user &&
                user.external_link !== "" &&
                user.external_link !== null &&
                ((isInstagramLink(user.external_link) && (
                  <a href={user.external_link}>
                    <InstagramOutlined
                      style={{ fontSize: "24px", marginTop: "8px" }}
                    />
                  </a>
                )) ||
                  (isLinkedInLink(user.external_link) && (
                    <a href={user.external_link}>
                      <LinkedinOutlined
                        style={{ fontSize: "24px", marginTop: "8px" }}
                      />
                    </a>
                  )) ||
                  (isTwitterLink(user.external_link) && (
                    <a href={user.external_link}>
                      <TwitterOutlined
                        style={{ fontSize: "24px", marginTop: "8px" }}
                      />
                    </a>
                  )) || <a href={user.external_link}>{user.external_link}</a>)}
            </Card>
          </Col>
          <Col span={15} xl={15} xxl={15} md={15} sm={24} xs={24}>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </Col>
          <Col span={1} xl={1} xxl={1} md={1} sm={0} xs={0}></Col>
        </Row>
      )}
      <UserModalForm />
    </div>
  );
};
export default observer(Profile);
