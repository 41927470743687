import React, { useState } from "react";
import { Modal, Button, Select, Form, Input, Image } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { EditOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import "../../style.css";

const { Option } = Select;

const UserModalForm = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  //const [PreviewImage, setPreviewImage] = useState(null);

  const { userStore } = useMobxStores();

  const [user_form] = Form.useForm();

  function isURL(str) {
    var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    return urlRegex.test(str);
  }

  const onCreateItem = (fields) => {
    const postData = new FormData();
    postData.append("bio", fields.bio);
    postData.append("first_name", fields.first_name);
    postData.append("last_name", fields.last_name);
    postData.append("email", fields.email);

    if (isURL(fields.external_link)) {
      postData.append("external_link", fields.external_link);
    }

    if (uploadedImage !== null) {
      postData.append("photo", uploadedImage);
    }

    userStore.UpdateUserProfile(postData);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    userStore.setIsUserModalOpen(false);
  };

  const handleCancel = () => {
    userStore.setIsUserModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="Update your profile"
        open={userStore.isUserModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"50%"}
        style={{ textAlign: "center", zIndex: 100000000 }}
        footer={null}
      >
        <Form
          name="user_form"
          initialValues={{
            remember: true,
          }}
          onFinish={onCreateItem}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          size="large"
          form={user_form}
          fields={userStore.formUserDetails}
        >
          <Form.Item>
            <Image
              src={
                uploadedImage
                  ? URL.createObjectURL(uploadedImage)
                  : userStore.user && userStore.user.photo
              }
              preview={false}
              className="custom-image"
              style={{ width: "100px", height: "100px" }}
            />
          </Form.Item>
          <Form.Item name="photo">
            <Upload
              maxCount={1}
              showUploadList={false}
              customRequest={() => {}}
              onChange={(file) => {
                setUploadedImage(file.file.originFileObj);
              }}
            >
              <Button icon={<EditOutlined />}>Edit Photo</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[
              {
                required: false,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[
              {
                required: false,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                required: false,
                message: "Please input your email!",
                type: "email",
              },
            ]}
          >
            <Input disabled={true} placeholder="E-mail" />
          </Form.Item>
          <Form.Item
            label="Bio"
            name="bio"
            rules={[
              {
                required: false,
                message: "Please input your Bio!",
              },
            ]}
          >
            <Input.TextArea placeholder="Bio" />
          </Form.Item>
          <Form.Item
            label="Link"
            name="external_link"
            rules={[
              {
                required: false,
                message: "Please input a link!",
                type: "url",
              },
            ]}
          >
            <Input placeholder="Link" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default observer(UserModalForm);
