import React from "react";
import { Modal } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";

const ErrorModal = () => {
  const { errorStore } = useMobxStores();

  const handleOk = () => {
    errorStore.setErrorModalIsOpen(false);
  };

  const handleCancel = () => {
    errorStore.setErrorModalIsOpen(false);
  };

  return (
    <>
      <Modal
        title="Something went wrong"
        open={errorStore.errorModalIsOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>{errorStore.message}</p>
        <p>Please try later</p>
      </Modal>
    </>
  );
};

export default observer(ErrorModal);
