import { makeAutoObservable } from "mobx";
import {
  POST,
  DELETE,
  GET,
  PUT,
  GET_CONTENT_MANAGEMENT,
  PATCH,
} from "../apis/apis";
import { Button } from "antd";
import React from "react";

class MarketPlaceStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }

  license = [];
  marketPlaceItems = [];
  marketPlaceItem = null;
  IsMarketplaceCreateModalOpen = false;
  cart = { items: [] };
  wishlist = { items: [] };
  wishlist_items_list = { key: "", label: <>''</> };
  cart_items_list = { key: "", label: <>''</> };
  metaMaskIsConnected = false;
  play = false;
  audioSource = null;
  playingId = null;

  setPlayingId = (value) => {
    this.playingId = value;
  };

  setPlay = (value) => {
    this.play = value;
  };

  setAudio = (value) => {
    this.audioSource = value;
  };

  setMetaMaskIsConnected = (value) => {
    this.metaMaskIsConnected = value;
  };

  setLicense = (value) => {
    this.license = value;
  };

  setCart = (value) => {
    this.cart = value;
  };

  setCartItemsList = (value) => {
    this.cart_items_list = value;
  };

  setIsMarketplaceCreateModalOpen = (value) => {
    this.IsMarketplaceCreateModalOpen = value;
  };

  setMarketPlaceItems = (value) => {
    this.marketPlaceItems = value;
  };

  CreatedMarketPlaceItems = [];
  OwnedMarketPlaceItems = [];

  setCreatedMarketPlaceItems = (value) => {
    this.CreatedMarketPlaceItems = value;
  };

  setOwnedMarketPlaceItems = (value) => {
    this.OwnedMarketPlaceItems = value;
  };

  polygon_icon = null;
  ethereum_icon = null;

  GetPolygonNetworkIcon = () => {
    GET(`/icon/${"polygon.png"}`)
      .then((data) => {
        this.polygon_icon = data.url;
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetEtheriumNetworkIcon = () => {
    GET(`/icon/${"ethereum.png"}`)
      .then((data) => {
        this.ethereum_icon = data.url;
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetCreatedMarketPlaceItems = (username) => {
    this.errStore.setLoading(true);
    this.errStore.setItemsRequest("Server is not responding. Please wait ...");
    GET(`/user/created/${username}`)
      .then((data) => {
        this.setCreatedMarketPlaceItems(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.errStore.setItemsRequest("");
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetOwnedMarketPlaceItems = (username) => {
    this.errStore.setLoading(true);
    this.errStore.setItemsRequest("Server is not responding. Please wait ...");
    GET(`/user/owned/${username}`)
      .then((data) => {
        this.setOwnedMarketPlaceItems(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.errStore.setItemsRequest("");
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  calculateItems = () => {};

  GetMarketPlaceItems = (param) => {
    this.errStore.setLoading(true);
    this.errStore.setItemsRequest("Server is not responding. Please wait ...");
    GET(`/marketplace-item/?ordering=${param}`)
      .then((data) => {
        this.setMarketPlaceItems(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.errStore.setInfo(false);
        this.errStore.setItemsRequest("");
        data.map((item, index) => {
          if (item.nft) {
            this.GetNFTById(item.nft);
          }
        });
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };
  unlockable_source = "";
  unlockable_content_text = null;

  setUnlockableContentText = (value) => {
    this.unlockable_content_text = value;
  };
  setUnlockableContentSource = (value) => {
    this.unlockable_source = value;
  };

  UnlockContent = (id) => {
    this.errStore.setLoading(true);
    const values = {
      account: localStorage.getItem("metaMask"),
    };
    POST(`/nft/${id}/unlock-content`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setUnlockableContentText(data.unlockable_content);
        this.setUnlockableContentSource(data.source);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
        this.errStore.setMessage(error.message);
        this.errStore.setInfo(true);
      });
  };

  PostMarketPlaceNFTItemHash = (id, values) => {
    this.errStore.setLoading(true);
    PUT(`/tx/${id}`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  marketPlaceItemIsCreating = false;

  setMarketPlaceItemIsCreating = (value) => {
    this.marketPlaceItemIsCreating = value;
  };

  CreateMarketPlaceItem = (values) => {
    this.errStore.setLoading(true);
    this.setMarketPlaceItemIsCreating(true);
    POST(`/marketplace-item/`, values)
      .then((data) => {
        if (data.payload.chainId) {
          data.payload.gas = JSON.stringify(data.payload.gas);
          data.payload.maxFeePerGas = JSON.stringify(data.payload.maxFeePerGas);
          data.payload.maxPriorityFeePerGas = JSON.stringify(
            data.payload.maxPriorityFeePerGas
          );
          const transactionObject = {
            from: data.payload.from,
            data: data.payload.data,
            to: data.payload.to,
            value: data.payload.value.toString(16),
          };
          window.ethereum
            .request({
              method: "eth_sendTransaction",
              params: [transactionObject],
            })
            .then((txHash) => {
              console.log("Transaction hash:", txHash);

              values = { tx_hash: txHash };

              this.PostMarketPlaceNFTItemHash(data.tx_receipt, values);
            })
            .catch((error) => {
              console.error("Transaction error:", error);
            });
        }
        this.setIsMarketplaceCreateModalOpen(false);
        let temp = this.marketPlaceItems;
        // unshift function used to add an element to the beggining of an array
        temp.unshift(data.item);
        this.setMarketPlaceItems(temp);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setMarketPlaceItemIsCreating(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  UpdateMarketPlaceItem = (id, values) => {
    this.errStore.setLoading(true);

    console.log(values);
    PATCH(`/marketplace-item/${id}`, values)
      .then((data) => {
        this.marketPlaceItem = data;
        data.nft !== null && this.GetNFTById(data.nft);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  source = null;

  setSource = (value) => {
    this.source = value;
  };
  GetBlobSourceById = (src) => {
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "image.png";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  GetSourceById = (id) => {
    this.errStore.setLoading(true);
    this.errStore.setItemsRequest("Server is not responding. Please wait ...");
    GET(`/source/${id}`)
      .then((data) => {
        this.setSource(data.media);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.errStore.setItemsRequest("");
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetMarketPlaceItemById = (id) => {
    this.errStore.setLoading(true);

    GET(`/marketplace-item/${id}`)
      .then((data) => {
        this.marketPlaceItem = data;
        data.nft !== null && this.GetNFTById(data.nft);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetMarketPlaceItemsByTypeOrName = (values) => {
    this.errStore.setLoading(true);
    GET(`/marketplace-item/${values.order}/${values.value}`)
      .then((data) => {
        this.setMarketPlaceItems(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  DeleteMarketPlaceItemById = (id) => {
    this.errStore.setLoading(true);
    DELETE(`/marketplace-item/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        for (var i = 0; i < this.marketPlaceItems.length; i++) {
          if (this.marketPlaceItems[i].id === id) {
            this.marketPlaceItems.splice(i, 1);
            break;
          }
        }
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  // cart
  GetCart = () => {
    this.errStore.setLoading(true);
    GET(`/cart/`)
      .then((data) => {
        this.setCart(data);
        this.setCartItemsList([]);
        let temp = [];
        data.items_list.forEach((item, index) => {
          temp.push({
            key: item.id,
            label: (
              <Button key={index} type="link">
                {item.name}
              </Button>
            ),
          });
        });
        this.setCartItemsList(temp);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  DeleteCart = () => {
    this.errStore.setLoading(true);
    DELETE(`/cart/`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  UpdateCart = (values) => {
    this.errStore.setLoading(true);
    PUT(`/cart/`, values)
      .then((data) => {
        this.setCart(data);
        let temp = [];
        data.items_list.forEach((item) => {
          temp.push({ key: item.id, label: <>{item.name}</> });
        });
        this.setCartItemsList(temp);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  CreateCart = () => {
    this.errStore.setLoading(true);
    POST(`/cart/`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  // wishlist

  setWishlist = (value) => {
    this.wishlist = value;
  };

  GetWishlist = () => {
    this.errStore.setLoading(true);
    GET(`/wishlist/`)
      .then((data) => {
        this.setWishlist(data);
        this.wishlist_items_list = data.items_list;
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  DeleteWishlist = () => {
    this.errStore.setLoading(true);
    DELETE(`/wishlist/`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  UpdateWishlist = (values) => {
    this.errStore.setLoading(true);
    PUT(`/wishlist/`, values)
      .then((data) => {
        this.setWishlist(data);
        this.wishlist_items_list = data.items_list;
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  CreateWishlist = () => {
    this.errStore.setLoading(true);
    POST(`/wishlist/`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  // Tags

  tags = [];
  setTags = (value) => {
    this.tags = value;
  };

  GetTags = () => {
    this.errStore.setLoading(true);
    GET(`/tag/`)
      .then((data) => {
        this.setTags(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  // License

  GetLicense = () => {
    this.errStore.setLoading(true);
    GET(`/license`)
      .then((data) => {
        this.setLicense(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  // nft

  GetNFT = () => {
    this.errStore.setLoading(true);
    GET(`/nft/`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetNFTById = (id) => {
    this.errStore.setLoading(true);
    GET(`/nft/${id}`)
      .then((data) => {
        this.setNFT(data);
        const filteredItems = this.marketPlaceItems.filter(
          (item) => item.nft === id
        );
        if (filteredItems.length > 0) {
          filteredItems[0].nft = data;
        }
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  DeleteNFT = (id) => {
    this.errStore.setLoading(true);
    DELETE(`/nft/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  UpdateNFT = () => {
    this.errStore.setLoading(true);
    PUT(`/nft/`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  NFT = { name: "", price: "", license: "", sypply: "" };

  setNFT = (value) => {
    this.NFT = value;
  };

  CreateNFT = (values) => {
    this.errStore.setLoading(true);
    POST(`/nft/`, values)
      .then((data) => {
        this.setNFT(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  BuyNFT = (id, values) => {
    this.errStore.setLoading(true);
    POST(`/nft/buy/${id}`, values)
      .then((data) => {
        if (data.info.message) {
          this.errStore.setMessage(data.info.message);
          this.errStore.setInfo(true);
        }
        data.info.payload.gas = JSON.stringify(data.info.payload.gas);
        data.info.payload.maxFeePerGas = JSON.stringify(
          data.info.payload.maxFeePerGas
        );
        data.info.payload.maxPriorityFeePerGas = JSON.stringify(
          data.info.payload.maxPriorityFeePerGas
        );
        const transactionObject = {
          from: data.info.payload.from,
          data: data.info.payload.data,
          to: data.info.payload.to,
          value: data.info.payload.value.toString(16),
        };
        window.ethereum
          .request({
            method: "eth_sendTransaction",
            params: [transactionObject],
          })
          .then((txHash) => {
            console.log("Transaction hash:", txHash);

            values = { tx_hash: txHash };
            this.PostMarketPlaceNFTItemHash(data.receipt, values);
          })
          .catch((error) => {
            console.error("Transaction error:", error);
          });
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  source = null;

  setSource = (value) => {
    this.source = value;
  };

  CreateSource = (values) => {
    this.errStore.setLoading(true);
    POST(`/source`, values)
      .then((data) => {
        this.setSource(data);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  source_link = null;

  setSourceLink = (value) => {
    this.source_link = value;
  };

  // displayImageIsUploading = false;
  // displayImageIsUploaded = false;

  // setDisplayImageIsUploading = (value) => {
  //   this.displayImageIsUploading = value;
  // };
  // setDisplayImageIsUploaded = (value) => {
  //   this.displayImageIsUploaded = value;
  // };

  sourceIsUploading = false;
  sourceIsUploaded = false;

  setSsourceIsUploading = (value) => {
    this.sourceIsUploading = value;
  };
  setSourceIsUploaded = (value) => {
    this.sourceIsUploaded = value;
  };

  AddSourceToIPFS = (values) => {
    this.errStore.setLoading(true);
    this.setSsourceIsUploading(true);
    POST(`/source/upload/ipfs`, values)
      .then((data) => {
        if (!data.link.includes("None")) {
          this.setSourceLink(data.link);
        }
        this.setSsourceIsUploading(false);
        this.setSourceIsUploaded(true);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
        this.setSsourceIsUploading(false);
      });
  };

  content_management_items = [];

  setContentManagementItems = (value) => {
    this.content_management_items = value;
  };

  GetItemsFromContentManagement = () => {
    this.errStore.setLoading(true);
    GET_CONTENT_MANAGEMENT(`contents/`)
      .then((data) => {
        this.setContentManagementItems(data.owner);
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };
}

export default MarketPlaceStore;
