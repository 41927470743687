import { makeAutoObservable } from "mobx";
import {
  GET_PLATFORM,
  POST_PLATFORM,
  PUT_PLATFORM,
  DELETE_PLATFORM,
} from "../apis/apis";

class CatalogueStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }

  catalogueItems = [];
  IsCatalogueModalOpen = false;
  user = {};
  updateModeOn = false;

  setupdateModeOn = (value) => {
    this.updateModeOn = value;
  };

  setIsCatalogueModalOpen = (value) => {
    this.IsCatalogueModalOpen = value;
  };

  setCatalogueItems = (value) => {
    this.catalogueItems = value;
  };
  setUser = (value) => {
    this.user = value;
  };

  catalogueDetails = [];

  SetCatalogueItemDetails = (record) => {
    this.catalogueDetails = [
      {
        name: ["title"],
        value: record.title,
      },
      {
        name: ["description"],
        value: record.description,
      },
      {
        name: ["type"],
        value: record.type,
      },
      {
        name: ["source"],
        value: record.source,
      },
    ];
  };

  GetCatalogueItems = () => {
    this.errStore.setLoading(true);
    GET_PLATFORM(`/catalogue-item`)
      .then((data) => {
        this.setCatalogueItems(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        // this.errStore.handlerErrors(error);
        console.log(error);
      });
  };
  GetUser = () => {
    this.errStore.setLoading(true);
    GET_PLATFORM(`/user`)
      .then((data) => {
        this.setUser(data);
        console.log(this.user);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        // this.errStore.handlerErrors(error);
        console.log(error);
      });
  };

  CreateCatalogueItem = (values) => {
    this.errStore.setLoading(true);
    POST_PLATFORM(`/catalogue-item`, values)
      .then((data) => {
        let temp = this.catalogueItems;
        // unshift function used to add an element to the beggining of an array
        temp.unshift(data);
        this.setCatalogueItems(temp);
        this.setIsCatalogueModalOpen(false);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  catalogueItemId = null;
  setCatalogueItemId = (value) => {
    this.catalogueItemId = value;
  };

  UpdateCatalogueItemById = (values) => {
    PUT_PLATFORM(`/catalogue-item/${this.catalogueItemId}`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.setIsCatalogueModalOpen(false);
        this.setupdateModeOn(false);
        for (var i = 0; i < this.catalogueItems.length; i++) {
          if (this.catalogueItems[i].id === this.catalogueItemId) {
            this.catalogueItems[i] = data;
            break;
          }
        }
      })
      .catch((err) => {
        this.errStore.handlerErrors(err);
      });
  };

  GetCatalogueById = (id) => {
    this.errStore.setLoading(true);
    GET_PLATFORM(`/catalogue-item/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetCatalogueByTitle = (title) => {
    this.errStore.setLoading(true);
    GET_PLATFORM(`/catalogue-item/title/${title}`)
      .then((data) => {
        this.catalogueItems = data;
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetCatalogueByType = (type) => {
    this.errStore.setLoading(true);
    GET_PLATFORM(`/catalogue-item/type/${type}`)
      .then((data) => {
        this.catalogueItems = data;
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  GetCatalogueItemsByTypeOrName = (values) => {
    this.errStore.setLoading(true);
    GET_PLATFORM(`/catalogue-item/${values.order}/${values.value}`)
      .then((data) => {
        this.setMarketPlaceItems(data);
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    arr.splice(objWithIdIndex, 1);
    return arr;
  }

  DeleteCatalogueItemById = (id) => {
    this.errStore.setLoading(true);
    DELETE_PLATFORM(`/catalogue-item/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setCatalogueItems(
          this.removeObjectWithId(this.catalogueItems, id)
        );
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };
}

export default CatalogueStore;
