import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";
import { notification, Button } from "antd";

const Messages = () => {
  const { errorStore, componentStore } = useMobxStores();
  const [color, setColor] = useState("green");

  const onClick = (e) => {
    componentStore.setComponent({ key: "7", id: "1", openModal: true });
  };

  useEffect(() => {
    switch (errorStore.status) {
      case "success":
        setColor("green");
        break;
      case "error":
        setColor("red");
        break;
      default:
        setColor("green");
    }
  });
  return (
    errorStore.message !== "" && (
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          width: "100%",
          backgroundColor: color,
          color: "white",
          textAlign: "center",
          animationName: "example",
          animationDuration: "4s",
        }}
      >
        {/* {notification.destroy()} */}

        {errorStore.info &&
          notification.info({
            message: <Button type="link">Information</Button>,
            description: errorStore.message,
            placement: errorStore.message_placement,
          }) &&
          notification.destroy()}

        {errorStore.warning &&
          notification.warning({
            message: (
              <Button onClick={() => onClick()} type="link">
                Warning
              </Button>
            ),
            description: errorStore.message,
            placement: errorStore.message_placement,
          }) &&
          notification.destroy()}

        {errorStore.error &&
          notification.error({
            message: (
              <Button onClick={() => onClick()} type="link">
                Error
              </Button>
            ),
            description: errorStore.message,
            placement: errorStore.message_placement,
          }) &&
          notification.destroy()}

        {errorStore.setMessage("")}
        {errorStore.setError(false)}
        {errorStore.setWarning(false)}
        {errorStore.setInfo(false)}
        {errorStore.setMessagePlacement("topRight")}
      </div>
    )
  );
};

export default observer(Messages);
