import React, { useEffect } from "react";
import { useMobxStores } from "../stores/stores";
import { observer } from "mobx-react";
import { Row, Col, FloatButton, Input, Empty, Tooltip } from "antd";
import Item from "../components/marketplace/Item";
import { FileAddOutlined } from "@ant-design/icons";
import CreateItem from "../components/marketplace/CreateItem";
import FilterOptions from "../components/marketplace/FilterOptions";

const Marketplace = () => {
  const { marketplaceStore } = useMobxStores();

  useEffect(() => {
    marketplaceStore.GetMarketPlaceItems();
    marketplaceStore.GetPolygonNetworkIcon();
    marketplaceStore.GetEtheriumNetworkIcon();
  }, [marketplaceStore]);

  return (
    <div style={{ paddingBottom: "100px", paddingLeft: "150px" }}>
      <CreateItem />
      <Row justify={"center"} align={"middle"} style={{ paddingTop: "2%" }}>
        <Col span={24} style={{ textAlign: "center" }}>
          <Input.Search
            style={{ width: "40%" }}
            onChange={(e) =>
              e.target.value !== ""
                ? marketplaceStore.GetMarketPlaceItemsByTypeOrName({
                    order: "name",
                    value: e.target.value,
                  })
                : marketplaceStore.GetMarketPlaceItems()
            }
            placeholder="Search an item by name"
          ></Input.Search>
        </Col>
      </Row>
      <div style={{ height: "30px" }}></div>

      <Row justify={"center"} align={"middle"}>
        <Col
          span={24}
          style={{
            textAlign: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <FilterOptions />
        </Col>
      </Row>
      <Row
        justify={"center"}
        align={"middle"}
        style={{ textAlign: "center" }}
        gutter={[20, 20]}
      ></Row>
      <div style={{ height: "30px" }}></div>
      <Row justify={"center"} align={"middle"} gutter={[20, 20]}>
        {marketplaceStore.marketPlaceItems.map((item, index) => {
          return (
            <Col key={index} span={6} xl={6} xxl={6} md={12} sm={12} xs={24}>
              <Item item={item} />
            </Col>
          );
        })}
        {marketplaceStore.marketPlaceItems.length === 0 && (
          <Empty description="No items found" />
        )}
      </Row>
      <Tooltip title="Add an item!">
        <FloatButton
          trigger="click"
          type="primary"
          style={{
            right: 24,
            width: "70px",
            height: "70px",
            boxShadow: "3px 3px 4px #999",
            zIndex: "1000",
          }}
          description=""
          icon={<FileAddOutlined style={{ fontSize: "24px" }} />}
          onClick={() => marketplaceStore.setIsMarketplaceCreateModalOpen(true)}
        />
      </Tooltip>
    </div>
  );
};
export default observer(Marketplace);
