import { Dropdown, Badge, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { ShoppingCartOutlined } from "@ant-design/icons";

var _ = require("lodash");

const CartDropdownMenu = () => {
  const { marketplaceStore } = useMobxStores();
  const [items, setItems] = useState(marketplaceStore.cart_items_list);

  useEffect(() => {
    setItems(_.cloneDeep(marketplaceStore.cart_items_list));
  }, [marketplaceStore.cart,marketplaceStore.cart_items_list]);

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button type="link" onClick={(e) => e.preventDefault()}>
        <Badge count={marketplaceStore.cart.items.length}>
          <ShoppingCartOutlined style={{ fontSize: "34px", color: "white" }} />
        </Badge>
      </Button>
    </Dropdown>
  );
};

export default observer(CartDropdownMenu);
