import React, { useEffect } from "react";
import { useMobxStores } from "../stores/stores";
import { observer } from "mobx-react";
import { Row, Col, Empty, Divider } from "antd";
import Item from "../components/marketplace/Item";

const Wishlist = () => {
  const { marketplaceStore } = useMobxStores();

  useEffect(() => {
    marketplaceStore.GetWishlist();
  }, [marketplaceStore.Wishlist]);

  return (
    <Row
      justify={"center"}
      align={"middle"}
      style={{ padding: "50px", textAlign: "center" }}
      gutter={[20, 20]}
    >
      {Array.isArray(marketplaceStore.wishlist_items_list) &&
        marketplaceStore.wishlist_items_list.map((item, index) => {
          return (
            <Col span={6} xl={6} xxl={6} md={6} sm={24} xs={24} key={index}>
              <Item item={item} />
            </Col>
          );
        })}
      {marketplaceStore.wishlist_items_list.length === 0 && (
        <Empty description="Your wishlist is empty" />
      )}
    </Row>
  );
};
export default observer(Wishlist);
