import React, { useState } from "react";
import { Button, Tag, Row, Col, Select } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";

const { Option } = Select;

const FilterOptions = () => {
  const { marketplaceStore } = useMobxStores();
  const [isFiltering, setIFiltering] = useState(false);

  const onClick = (values) => {
    setIFiltering(true);
    marketplaceStore.GetMarketPlaceItemsByTypeOrName(values);
  };

  const OnResetFiltering = () => {
    marketplaceStore.GetMarketPlaceItems();
    setIFiltering(false);
  };

  const tags_list = [
    { name: "software", type: "tag", color: "yellow", value: "software" },
    { name: "3D", type: "tag", color: "red", value: "3D" },
    { name: "dataset", type: "tag", color: "black", value: "dataset" },
    { name: "learn", type: "tag", color: "orange", value: "learn" },
  ];

  const types_list = [
    { name: "software", type: "type", color: "blue", value: "software" },
    { name: "3D Model", type: "type", color: "brown", value: "3D_model" },
    { name: "dataset", type: "type", color: "pink", value: "dataset" },
    // { name: "learn", type: "type", color: "green", value: "learn" },
    { name: "MOOC", type: "type", color: "indigo", value: "MOOC" },
  ];

  const sort_options = [
    { name: "Oldest", value: "created" },
    { name: "Popularity ascending", value: "views" },
    { name: "Newest", value: "-created" },
    { name: "Popularity descending", value: "-views" },
  ];

  return (
    <Row justify={"start"} align={"middle"} gutter={[20, 20]}>
      <Col span={24} style={{ textAlign: "center" }}>
        Tags:{" "}
        {tags_list.map((item, index) => {
          return (
            <Tag
              key={index}
              onClick={() => onClick({ order: item.type, value: item.value })}
              color={"white"}
              style={{
                width: "70px",
                textAlign: "center",
                cursor: "pointer",
                color: "white",
                borderRadius: "15px",
              }}
              className="tags-color"
            >
              {item.name}
            </Tag>
          );
        })}
        Types:{" "}
        {types_list.map((item, index) => {
          return (
            <Tag
              key={index}
              onClick={() => onClick({ order: item.type, value: item.value })}
              color={"white"}
              style={{
                width: "70px",
                textAlign: "center",
                cursor: "pointer",
                color: "white",
                borderRadius: "15px",
              }}
              className="types-color "
            >
              {item.name}
            </Tag>
          );
        })}
        {isFiltering && (
          <Button onClick={() => OnResetFiltering()}>Clean filters</Button>
        )}
      </Col>
      <Row justify={"end"} style={{ paddingLeft: "70%" }}>
        <Col>
          <Select
            placeholder={"sort items"}
            width={"auto"}
            style={{ width: "auto" }}
            popupMatchSelectWidth={false}
          >
            {sort_options.map((item, index) => {
              return (
                <Option key={index} value={item.name}>
                  <Button
                    type="text"
                    onClick={() =>
                      marketplaceStore.GetMarketPlaceItems(item.value)
                    }
                  >
                    {item.name}
                  </Button>
                </Option>
              );
            })}
          </Select>
        </Col>
      </Row>
    </Row>
  );
};

export default observer(FilterOptions);
