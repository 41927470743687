import React, { useState } from "react";
import { Modal, Button, Select, Form, Input } from "antd";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";

const { Option } = Select;

const CreateUpdateCatalogueItem = (props) => {
  const { catalogueStore } = useMobxStores();
  const [form_create_update_catalogue_item] = Form.useForm();

  const onCreateUpdateItem = (fields) => {
    catalogueStore.updateModeOn
      ? catalogueStore.UpdateCatalogueItemById(fields)
      : catalogueStore.CreateCatalogueItem(fields);
    form_create_update_catalogue_item.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      catalogueStore.setIsCatalogueModalOpen(false);
    }, 2000);
  };

  const handleCancel = () => {
    catalogueStore.setIsCatalogueModalOpen(false);
    catalogueStore.setupdateModeOn(false)
    form_create_update_catalogue_item.resetFields();
  };

  const types = [
    {
      label: "Marketplace Asset",
      value: "Marketplace Asset",
    },
    {
      label: "Software Repository",
      value: "Software Repository",
    },
    {
      label: "MOOC",
      value: "MOOC",
    },
  ];

  return (
    <div>
      <Modal
        title={
          catalogueStore.updateModeOn
            ? "Update catalogue item"
            : "Create Catalogue Item"
        }
        open={catalogueStore.IsCatalogueModalOpen}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
        width={"80%"}
        style={{ textAlign: "center" }}
      >
        <Form
          form={form_create_update_catalogue_item}
          name="form_create_update_catalogue_item"
          onFinish={onCreateUpdateItem}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          fields={catalogueStore.catalogueDetails}
          layout="vertical"
          size="large"
          initialValues={{
            resetFields: true,
          }}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Please input a title!",
              },
            ]}
          >
            <Input placeholder="Title" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please input a description!",
              },
            ]}
          >
            <Input placeholder="Description" />
          </Form.Item>

          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: "Please input a type!",
              },
            ]}
          >
            <Select placeholder={"Type"}>
              {types.map((item, index) => {
                return (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Source"
            name="source"
            rules={[
              {
                required: true,
                message: "Please input a source!",
                type: "url",
              },
            ]}
          >
            <Input placeholder="Source" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              {catalogueStore.updateModeOn ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default observer(CreateUpdateCatalogueItem);
