import React, { useEffect } from "react";
import { useMobxStores } from "../stores/stores";
import { observer } from "mobx-react";
import {
  Row,
  Col,
  Space,
  Tag,
  Button,
  Table,
  Divider,
  Popover,
  Empty,
  Select,
} from "antd";
import { getOverridedColumns } from "../utils/tableLoader";
import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import CreateUpdateCatalogueItem from "../components/catalogue/CreateUpdateCatalogueItem";
import Input from "antd/es/input/Input";

var _ = require("lodash");

const Catalogue = () => {
  const { catalogueStore, userStore } = useMobxStores();

  const onDeleteCatalogueItem = (id) => {
    catalogueStore.DeleteCatalogueItemById(id);
  };

  const handleChange = (value) => {
    value === "All Types"
      ? catalogueStore.GetCatalogueItems()
      : catalogueStore.GetCatalogueByType(value);
  };

  const onUpdateCatalogueItem = (record) => {
    catalogueStore.setupdateModeOn(true);
    catalogueStore.setIsCatalogueModalOpen(true);
    catalogueStore.setCatalogueItemId(record.id);
    catalogueStore.SetCatalogueItemDetails(record);
  };

  const columns = [
    {
      title: "Title",
      responsive: ["lg", "sm", "md", "xs"],
      key: "Title",
      width: "auto",
      render: (record) => <p>{record.title}</p>,
    },
    {
      title: "Description",
      responsive: ["lg", "sm", "md", "xs"],
      key: "description",
      width: "auto",
      render: (record) => <p>{record.description}</p>,
    },
    {
      title: "Type",
      responsive: ["lg", "sm", "md", "xs"],
      key: "type",
      width: "auto",
      render: (record) => (
        <Tag color={record.type === "Marketplace Asset" ? "blue" : "gold"}>
          {record.type}
        </Tag>
      ),
    },
    {
      title: "Source",
      responsive: ["lg", "sm", "md", "xs"],
      key: "source",
      width: "auto",
      render: (record) => <a href={record.source}>{record.source}</a>,
    },
    {
      title: "Actions",
      responsive: ["lg", "sm", "md", "xs"],
      key: "actions",
      width: "auto",
      render: (record) =>
        record.owner == catalogueStore.user.id ? (
          <Space>
            <Popover title="Delete this item">
              <Button
                onClick={() => onDeleteCatalogueItem(record.id)}
                icon={<DeleteOutlined style={{ color: "red" }} />}
              />
            </Popover>
            <Popover title="Update this item">
              {" "}
              <Button
                onClick={() => onUpdateCatalogueItem(record)}
                icon={<FormOutlined />}
                style={{ color: "blue" }}
              />
            </Popover>
          </Space>
        ) : (
          " "
          // console.log(catalogueStore.user)
        ),
    },
  ];

  useEffect(() => {
    catalogueStore.GetCatalogueItems();
    catalogueStore.GetUser();
  }, []);

  return (
    <div>
      <Row
        justify={"center"}
        align={"middle"}
        style={{ padding: "10px", textAlign: "center", paddingTop: "2%" }}
      >
        <Col span={15}>
          <b style={{ fontSize: "24px", color: "rgb(0, 0, 128, 1)" }}>
            Catalogue, find everything XR2Learn has to offer!
          </b>
        </Col>
      </Row>
      <Row
        justify={"center"}
        align={"middle"}
        style={{ paddingTop: "2%" }}
        gutter={[20, 20]}
      >
        <Col span={2}></Col>
        <Col span={4}>
          <Button
            style={{ margin: "10px" }}
            type="primary"
            onClick={() => catalogueStore.setIsCatalogueModalOpen(true)}
          >
            Add an Item
          </Button>
        </Col>
        <Col span={12}>
          <Input.Search
            style={{ width: "100%" }}
            placeholder="Search an item by title"
            onChange={(e) =>
              e.target.value !== ""
                ? catalogueStore.GetCatalogueByTitle(e.target.value)
                : catalogueStore.GetCatalogueItems()
            }
          />
        </Col>
        <Col span={4}>
          <Select
            defaultValue="All types"
            style={{
              width: "auto",
              minWidth: "200px",
            }}
            onChange={handleChange}
            options={[
              {
                value: "Marketplace Asset",
                label: "Marketplace Asset",
              },
              {
                value: "Software Repository",
                label: "Software Repository",
              },
              {
                value: "MOOC",
                label: "MOOC",
              },
              {
                value: "All Types",
                label: "All Types",
              },
            ]}
          />
        </Col>
        <Col span={2}></Col>
      </Row>
      <Row
        justify={"center"}
        align={"middle"}
        style={{ padding: "10px", textAlign: "left" }}
      >
        <Col span={20}>
          <Divider> Catalogue items</Divider>
          {catalogueStore.catalogueItems.length != 0 ? (
            <Table
              columns={
                _.cloneDeep(catalogueStore.catalogueItems).length > 0
                  ? columns
                  : getOverridedColumns(columns)
              }
              dataSource={
                _.cloneDeep(catalogueStore.catalogueItems).length > 0
                  ? _.cloneDeep(catalogueStore.catalogueItems)
                  : Array(5).fill({})
              }
              rowKey={(record) => record.id}
              bordered={true}
            />
          ) : (
            <Empty />
          )}
        </Col>
      </Row>
      <CreateUpdateCatalogueItem />
    </div>
  );
};
export default observer(Catalogue);
