import React, { useEffect } from "react";
import { useMobxStores } from "../stores/stores";
import { observer } from "mobx-react";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";
import Item from "../components/marketplace/Item";

const MarketplaceItem = () => {
  const { marketplaceStore } = useMobxStores();
  let params = useParams();

  useEffect(() => {
    marketplaceStore.GetMarketPlaceItemById(params.pk);
  }, []);

  return (
    <div>
      <Row
        style={{ paddingTop: "10px" }}
        justify={"center"}
        align={"middle"}
        gutter={[20, 20]}
      >
        <Col span={10} xl={10} xxl={10} md={10} sm={24} xs={24}>
          {marketplaceStore.marketPlaceItem !== null ? (
            <Item single={true} item={marketplaceStore.marketPlaceItem} />
          ) : (
            "Loading..."
          )}
        </Col>
      </Row>
    </div>
  );
};

export default observer(MarketplaceItem);
