import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Typography, Button, Space } from "antd";
import "./CookieConsent.css";

const { Text, Link: AntLink } = Typography;
const { Footer } = Layout;

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(() => {
    const consent = localStorage.getItem("cookieConsent");
    return !consent;
  });

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setIsVisible(false); // Keep the consent prompt visible on decline
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Footer className="cookie-consent">
      <Space direction="vertical" className="cookie-consent-content">
        <Text style={{ color: "#fff" }}>
          We use cookies to ensure you get the best experience on our website.
          For further information please refer to our{" "}
          <AntLink>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </AntLink>
        </Text>
        <Space>
          <Button
            type="primary"
            onClick={handleAccept}
            style={{ display: "flex", alignItems: "center", height: "40px" }}
          >
            Accept
          </Button>
          <Button
            type="primary"
            onClick={handleDecline}
            style={{ display: "flex", alignItems: "center", height: "40px" }}
          >
            Decline
          </Button>
        </Space>
      </Space>
    </Footer>
  );
};

export default CookieConsent;
