import { getEnvVar } from "./envVars";

export const constants = {
  AUTH_URL: getEnvVar("{AUTH_URL}"),
  AUTH_REALM: getEnvVar("{AUTH_REALM}"),
  AUTH_CLIENT: getEnvVar("{AUTH_CLIENT}"),
  AUTH_CLIENT_SECRET: getEnvVar("{AUTH_CLIENT_SECRET}"),
  API: getEnvVar("{API}"),
  FORUM_URL: getEnvVar("{FORUM_URL}"),
  PLATFORM: getEnvVar("{PLATFORM}"),
  PORT: getEnvVar("{PORT}"),
};
