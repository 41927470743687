import { makeAutoObservable } from "mobx";

class ErrorStore {
  constructor() {
    makeAutoObservable(this);
  }
  status = "success";
  message = "";
  loading = false;
  error_message = "";
  message_placement = "topRight";
  error = false;
  info = false;
  panic = false;
  warning = false;
  items_request = "";
  errorModalIsOpen = false;

  setMessage = (message) => {
    this.message = message;
  };

  handlerErrors = (err) => {
    console.log(err);
    this.status = "error";
    this.loading = false;
    this.setMessage(err.message);
    this.setItemsRequest("");
    this.setErrorModalIsOpen(true);
  };

  handlerCussess = (message) => {
    this.status = "success";
    this.message = message;
    this.loading = false;
  };

  setErrorModalIsOpen = (value) => {
    this.errorModalIsOpen = value;
  };

  setLoading = (loading) => {
    this.loading = loading;
  };

  setItemsRequest = (message) => {
    setTimeout(() => {
      this.items_request = message;
    }, 5000);
  };

  setStatus = (status) => {
    this.status = status;
  };

  setMessagePlacement = (value) => {
    this.message_placement = value;
  };

  setInfo = (value) => {
    this.info = value;
  };

  setError = (value) => {
    this.error = value;
  };

  setWarning = (value) => {
    this.warning = value;
  };

  setPanic = (value) => {
    this.panic = value;
  };
}

export default ErrorStore;
