import React from "react";
import { FloatButton } from "antd";
import { observer } from "mobx-react";

const BackToTop = () => {
  return (
    <FloatButton.BackTop
      style={{ marginBottom: "100px", zIndex: "1000" }}
      type="primary"
    />
  );
};

export default observer(BackToTop);
