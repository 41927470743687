import React from "react";
import { Col, Row, Result } from "antd";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

const NotFound = () => {

  return (
    <Row justify="center" align="center" className="full-viewport">
      <Col span={8} lg={8} xs={24} sm={24}>
        <Result
          status={"404"}
          title={'Page does not exist!'}
          extra={[
            <Link to='/' >Home Page</Link>,
          ]}
        />
      </Col>
    </Row>
  );
};

export default observer(NotFound);
