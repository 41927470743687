import React from "react";
import { Alert } from "antd";
import Marquee from "react-fast-marquee";
import { observer } from "mobx-react";
import { useMobxStores } from "../../stores/stores";

const MessageBanner = (props) => {
  const { errorStore } = useMobxStores();
  return (
    <>
      {errorStore.items_request !== "" && (
        <Alert
          banner
          message={
            <Marquee pauseOnHover gradient={false}>
              {errorStore.items_request}
            </Marquee>
          }
        />
      )}
    </>
  );
};

export default observer(MessageBanner);
