import React, { useEffect, useState } from "react";
import MainHeader from "./components/header/MainHeader";
import NotFound from "./pages/404";
import BackToTop from "./components/BackToTop/BackToTop";
import MainLoader from "./components/loader/MainLoader";
import { observer } from "mobx-react";
import { Row, Col, ConfigProvider } from "antd";
import { getStores, StoreProvider } from "./stores/stores";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Marketplace from "./pages/Marketplace";
import Profile from "./pages/Profile";
import Wishlist from "./pages/Wishlist";
import Catalogue from "./pages/Catalogue";
import MarketplaceItem from "./pages/MarketplaceItem";
import Messages from "./components/messages/Messages";
import Home from "./pages/Home";
import MessageBanner from "./components/messages/MessageBanner";
import ErrorModal from "./components/messages/ErrorModal";
import CookieConsent from "./components/utils/CookieConsent";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./components/footer/Footer";

const App = (props) => {
  const mobxStores = getStores();
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("kc_token");

  useEffect(() => {
    // mobxStores.marketplaceStore.GetLicense();
    // mobxStores.marketplaceStore.GetCart();
    // mobxStores.userStore.GetUser();
    // mobxStores.marketplaceStore.GetWishlist();
    //mobxStores.marketplaceStore.GetPolygonNetworkIcon();
    //mobxStores.marketplaceStore.GetEtheriumNetworkIcon();
    setTimeout(() => setLoading(false), 2000);
  }, [token, mobxStores.marketplaceStore, mobxStores.userStore]);

  return loading === false ? (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "rgb(201 42 213)",
          fontSize: 15,
          colorText: "black",
          colorInfo: mobxStores.styleStore.primaryColor,
          lineWidth: 2,
        },
        components: {
          Menu: {
            // colorPrimary: mobxStores.styleStore.primaryColor,
            colorTextBase: "white",
          },
        },
      }}
    >
      <BrowserRouter>
        <StoreProvider value={mobxStores}>
          <Row className="full-viewport">
            <Col
              span={24}
              className={
                mobxStores.styleStore.styleClass +
                " " +
                mobxStores.styleStore.fontSize
              }
            >
              <MainHeader onLogout={props.onLogout} token={props.token} />
              <BackToTop />
              <Messages />
              <MessageBanner />
              <ErrorModal />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/catalogue" element={<Catalogue />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route path="/marketplace/:pk" element={<MarketplaceItem />} />
                <Route path="/portfolio/:username" element={<Profile />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                {/* Add route for PrivacyPolicy */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Col>
          </Row>
          <Footer />
          <CookieConsent /> {/* Add CookieConsent here */}
        </StoreProvider>
      </BrowserRouter>
    </ConfigProvider>
  ) : (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: mobxStores.styleStore.primaryColor,
          fontSize: 15,
          colorText: "black",
          colorInfo: mobxStores.styleStore.primaryColor,
          lineWidth: 2,
        },
      }}
    >
      <MainLoader />
    </ConfigProvider>
  );
};

export default observer(App);
