import { makeAutoObservable } from "mobx";

class StyleStore {
  constructor() {
    makeAutoObservable(this);
  }
  styleClass = "";
  fontSize = "";
  // Pantone 320
  primaryColor = "#612cd9"
  secondaryColor = "#9CDBD9"

  setStyleClass = (value) => {
    this.styleClass = value;
  };

  setFontSize = (value) => {
    this.fontSize = value;
  };
}

export default StyleStore;
