// src/pages/PrivacyPolicy.js
import React from "react";
import { Typography, Layout, Image } from "antd";
// import backgroundImage from "../../public/background-image.png";

const { Title, Paragraph } = Typography;
const { Content } = Layout;
// import backgroundImage from "../";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        backgroundImage: `url(background-image.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        overflow: "auto",
        backgroundPosition: "center",
      }}
    >
      <Layout
        style={{
          padding: "20px",
          maxWidth: "800px",
          margin: "auto",

          backgroundColor: "rgba(0, 0, 125, 0.45)",
          //   textAlign:"center"
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image
          style={{
            cursor: "pointer",
            maxWidth: "300px",
            alignItems: "center",
          }}
          //   onClick={() => navigate("/")}
          preview={false}
          src="xr2learn_logo.png"
        />
        <Content>
          <Typography style={{ color: "#fff" }}>
            <Title level={1} style={{ color: "#fff", textAlign: "center" }}>
              Personal Data Protection Policy
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              This is the main privacy policy for xr2learn-marketplace.eu and
              for certain services provided through it by XR2LEARN (“XR2LEARN”).
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              At XR2LEARN we collect different types of information about our
              users for two main reasons:
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              <ol>
                <li>
                  To provide personalised services unique to individual users;
                </li>
                <li>
                  To help us to monitor and improve the services we offer.
                </li>
              </ol>
            </Paragraph>
            <Title level={2} style={{ color: "#fff" }}>
              1.1 Our principles
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              XR2LEARN adopts the necessary technical and organisational
              measures to avoid the loss, misuse, alteration, unauthorised
              access, or theft of the personal data received, taking into
              account the state of technology, the nature of the data, and the
              risks to which they are exposed. This means:
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              <ul>
                <li>
                  We make sure that we have in place appropriate security
                  measures to protect your information; and
                </li>
                <li>
                  We make sure that when we ask another organisation to provide
                  a service for us, they have in place appropriate security
                  measures as well.
                </li>
              </ul>
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              To respect your privacy is topmost of our concerns. You shall not
              receive any marketing emails from us or from other organisations.
              However, we may email you occasionally with information or
              questions about your registration, your account, or postings, for
              example, with reminders, warnings, business opportunities, or
              copyright requests.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              We will collect and use individual user details only if we have
              explicit permission to do so.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              We will be clear in our dealings with you as to what information
              about you we will collect and how we will use it.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              We will use personal information only for the purposes for which
              it was originally collected, how they are defined at the beginning
              of this document, and we will make sure we delete it securely.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              Our Site is accessible via the internet. This means that people
              around the World who access our site can see anything you post on
              the site or LinkedIn comments with the XR2LEARN hash.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              If we or our service providers transfer any information out of the
              European Union (EU), it will only be done with the relevant
              protection (stated under European law) being in place.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              The information collected from you consists of: 1) when you
              register or become a member of our marketplace; and 2) when you
              use the site through cookies. Certain services that we provide may
              involve us collecting extra information (blockchain wallet
              address, for example), for the service to be provided as designed.
            </Paragraph>
            <Title level={2} style={{ color: "#fff" }}>
              1.2 Registration
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              The minimum information we need to register you is your name,
              surname, email address, and a password. We might ask you for more
              information for different services, including blockchain wallet
              address. Unless we say otherwise, you have to provide all the
              registration information needed for the specific service.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              All responsibility for the completion of forms with false,
              inaccurate, incomplete, or outdated information shall vest on the
              Users.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              After you have registered, and with your permission, we may send
              you emails we think may be of interest to you. At any time, you
              can decide not to receive these emails and will be able to
              ‘unsubscribe’.
            </Paragraph>
            <Title level={3} style={{ color: "#fff" }}>
              1.2.1 How long do we keep your data?
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              We process and store your personal data as long as it is necessary
              to fulfil legal obligations, or to fulfil legitimate interests.
              When this data is no longer necessary for the above purposes, it
              will be regularly deleted, except where - limited - data retention
              periods are required by the European Law. The retention period
              specified in the above-mentioned law is six years.
            </Paragraph>
            <Title level={2} style={{ color: "#fff" }}>
              1.3 Who we share data with
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              We will not share your personal information with others for
              marketing purposes.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              We can access and release personal information to keep to relevant
              laws and government requests, to operate our systems properly and
              to protect both us and our users.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              Any other organisations who access your information in the course
              of providing services on our behalf will be governed by strict
              contractual restrictions to make sure that they protect your
              information and keep to data-protection and privacy laws which
              apply. We may also independently audit these service providers to
              make sure that they meet our standards. We may use service
              providers to help us run these sites (without sharing personal
              data), some of which may be based outside the EU.
            </Paragraph>
            <Title level={2} style={{ color: "#fff" }}>
              1.4 Cookies
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              We make use of cookies when you visit Our Site, including both
              cookies we create as well as third party cookies including but not
              limited to Google Analytics. These cookies are placed on your
              computer or other device and perform various functions. Some of
              these cookies are necessary for the operation of the Website.
              Other cookies are subject to your permission and they can help
              distinguish you from other visitors to Our websites, improve the
              user experience, measure traffic, collect and measure certain
              statistics, and deliver relevant ads.
            </Paragraph>
            <Title level={3} style={{ color: "#fff" }}>
              1.4.1 Google Analytics
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              We use Google Analytics on our sites for anonymous reporting of
              site usage. If you would like to opt-out of Google Analytics
              monitoring your behaviour on our sites please use this link{" "}
              <a href="https://tools.google.com/dlpage/gaoptout">here</a>.
            </Paragraph>
            <Title level={2} style={{ color: "#fff" }}>
              1.5 Social Media Plug-Ins
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              Our Site might use plug-ins from Facebook, X (Twitter), and
              LinkedIn. When you visit a page of our site, your browser does not
              automatically establish a direct connection to the social media
              servers. A connection only takes place if you click the link to
              the social media servers. In this case, the social media provider
              will be informed that you have visited our site with your IP
              address.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              We would like to point out that as a provider of our site, we do
              not receive from the social media providers any knowledge of the
              content of the transmitted data nor its use.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              For further information go to the social media providers privacy
              statements.
            </Paragraph>
            <Title level={2} style={{ color: "#fff" }}>
              1.6 Legal information and how to contact us
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              XR2LEARN advises users of Our Site (“the Users”) that XR2LEARN
              complies with the current European legislation related to Personal
              Data Protection, Users' Privacy, and the Secrecy And Security of
              Personal Data, as established in the EU General Data Protection
              Regulation. The data controller is the organisation responsible
              for protecting information and, in our case, is Consorzio
              Nazionale Interuniversitario per le Telecomunicazioni (C.N.I.T.)
              established in Viale G.P. Usberti, 181/A, Pal. 3, 43124 Parma (PR)
              – (Italia). The Contact of the Data Protection Officer is
              cnit@pec.it.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              When you access or use the Site, we collect, use, share, and
              otherwise process your personal data for the purposes described in
              this Privacy Policy. We rely on a number of legal bases to use
              your information in these ways.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              <ol>
                <li>
                  We process your personal data with your consent, for example,
                  to: communicate with you; send you notifications; and for any
                  other purpose that we communicate to you and to which you have
                  consented.
                </li>
                <li>
                  We process your personal data in order to provide you with our
                  Site, for example, to: provide, operate and maintain the Site;
                  and investigate, address, and prevent conduct that may violate
                  our Terms of Service.
                </li>
                <li>
                  We process your personal data pursuant to our legitimate
                  interests to provide effective services to you and maintain
                  our business relationship, for example, to: improve and
                  analyse the Site.
                </li>
                <li>
                  We process your personal data in order to comply with legal
                  obligations, in the public interest, or in your vital
                  interests, for example, to: detect, prevent, and address
                  activities that we consider could be fraudulent, violations of
                  our Terms of Service or policies, and/or otherwise harmful or
                  unlawful.
                </li>
              </ol>
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              XR2LEARN is subject to various legal obligations (e.g. in
              accordance with the European laws).
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              If you would like access to or a copy of the personal information
              we hold about you, to request a correction, or have any questions
              about how we may use it or to make a complaint, please contact the
              Data Protection Officer at the address shown above or just email
              to admin@xr2learn-marketplace.eu.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              Complaints will be dealt with by the Data Protection Officer and
              will be responded to within 30 days at the latest.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              If you are not satisfied with the way your complaint was handled,
              you may be able to refer your complaint to your local data
              protection regulator.
            </Paragraph>
            <Title level={3} style={{ color: "#fff" }}>
              1.6.1 Third-Party Websites
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              Our Site may contain links to third-party websites. When you click
              on a link to any other website or location, you will leave our
              Service and go to another site, and another entity may collect
              information from you. We have no control over and cannot be
              responsible for these third-party websites or their content.
              Please be aware that this Privacy Policy does not apply to these
              third-party websites or their content, or to any collection of
              your information or information about you after you click on links
              to such third-party websites. We encourage you to read the privacy
              policies of every website you visit. Any links to third-party
              websites or locations are for your convenience and do not signify
              our endorsement of such third parties or their products, content,
              or websites.
            </Paragraph>
            <Title level={3} style={{ color: "#fff" }}>
              1.6.2 Third-Party Wallets
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              To use certain aspects of our Site, you must use a wallet which
              allows you to engage in transactions on public blockchains. Your
              interactions with any third-party wallet provider are governed by
              the applicable terms of service and privacy policy of that third
              party.
            </Paragraph>
            <Title level={2} style={{ color: "#fff" }}>
              1.7 Your rights at a glance
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              In accordance with the General Data Protection Regulation (GDPR)
              you have the right of access (Art. 15), right of rectification
              (Art. 16), right to erasure (Art. 17), right to restriction of
              processing (Art. 18), right to object (Art. 21) and the right to
              data portability (Art. 20). Furthermore, you have the right to
              lodge a complaint with a supervisory authority (Art. 77 GDPR)
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              Your consent to process personal data can be revoked at any time.
              Please note that such revocation of consent only applies from the
              time of revocation onwards. Any processing that had taken place
              before that time is not affected.
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              Information on your right to object in acc. with Art. 21 of the
              GDPR
            </Paragraph>
            <Paragraph style={{ color: "#fff" }}>
              a) Individual right to object
              <br />
              You have the right to object, on grounds relating to your
              particular situation, at any time to the processing of your
              personal data. The prerequisite for this is that the processing is
              in the public interest or on the basis of legitimate
              interest/balancing of interests. This also applies to profiling.
              In the case of such an objection, we will no longer process your
              personal data. An exception to this is if we can demonstrate
              compelling legitimate grounds for the processing of this data
              which override your interests, rights and freedoms, or the data
              serve the establishment, exercise or defence of legal claims.
              <br />
              b) Objections to using your data for direct marketing
              <br />
              In individual cases or where you have given your consent, we use
              your personal data for direct marketing purposes. You have the
              right to object at any time to the processing of your data for
              such marketing; this also applies to profiling to the extent that
              it is related to direct marketing. In the case of such an
              objection, we will no longer process your personal data for this
              purpose. Objections can take any form and should be sent to the
              address for our Data Protection Officer given above.
            </Paragraph>
            <Title level={2} style={{ color: "#fff" }}>
              1.8 Changes to the privacy policy
            </Title>
            <Paragraph style={{ color: "#fff" }}>
              Should we elect to change our privacy policy we will post the
              changes here. Where the changes are significant, we may also
              choose to email all our Users with the new details. Where required
              by law, will we obtain your consent to make these changes.
            </Paragraph>
          </Typography>
        </Content>
      </Layout>
    </div>
  );
};

export default PrivacyPolicy;
